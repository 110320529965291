import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import GradientTag from '../../../components/reusable/GradientTag';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import isMobile from 'is-mobile';
import { useIsomorphicLayoutEffect } from '../../../hooks/useIsomorphicLayoutEffect';
// assets
import Workflow from '../../../images/product-assets/idv-design-your-workflow-image.svg';
import VerificationLink from '../../../images/product-assets/idv-verification-link-image.svg';
import VerificationResult from '../../../images/product-assets/idv-get-verification-image.svg';
import Ellipse1 from '../../../images/product-assets/Ellipse1.svg';
import Ellipse2 from '../../../images/product-assets/Ellipse2.svg';

const Container = styled.section`
    background: #fff;
    position: relative;
    overflow: hidden;
`;

const IdentitiesContainer = styled.div`
    z-index: 2;
    max-width: 1280px;
    margin: 0 auto;
    padding: 80px 20px;

    @media screen and (max-width: 500px) {
        padding: 64px 16px;
    }
`;

const Heading = styled.h1`
    color: #03045e;
    font-size: 52px;
    font-weight: 500;
    line-height: 62px;
    width: 50%;

    @media screen and (max-width: 500px) {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        width: 100%;
    }
`;

const CardContainer = styled.div`
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    place-content: center;

    @media screen and (max-width: 500px) {
        margin-top: 36px;
        display: flex;
    }
`;

const LeftSection = styled.div`
    background-color: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 500px) {
        gap: 36px;
    }
`;
const RightSection = styled.div`
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const CardWrapper = styled.div``;

const InfoCards = styled.div`
    padding: 24px;
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        padding: 16px;
    }
`;
const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;

const CardHeading = styled.p`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #333;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }
`;

const CardDesc = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #52525b;
    margin-top: 24px;

    @media screen and (max-width: 500px) {
        margin-top: 16px;
        font-size: 14px;
        line-height: 18px;
    }
`;

const fadeInContainer = keyframes`
 0% {opacity:0;}
  100% {opacity:1;}
`;

const Screens = styled.img`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    animation: ${fadeInContainer} 3s;
    transition: all 0.3s;
`;

const MobileImg = styled.img`
    @media screen and (min-width: 500px) {
        display: none;
    }

    margin-bottom: 24px;
    width: 100%;
    padding: 0 32px;
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 3px;
    background: #6298ea;
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const EllipseOne = styled.img`
    position: absolute;
    left: -4%;
    bottom: 0;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const EllipseTwo = styled.img`
    position: absolute;
    right: -5%;
    bottom: 40%;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

function Identities() {
    const [screenImg, setScreenImg] = useState('workflow');

    const [firstProgress, setFirstProgress] = useState(null);
    const [secondProgress, setSecondProgress] = useState(null);
    const [thirdProgress, setThirdProgress] = useState(null);

    const resetProgress = (progress) => {
        if (!progress) return;
        progress.pause();
        progress.time(0);
    };
    const startProgress = (progress) => {
        progress.play();
    };

    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const timer = useRef(null);
    const IdentitiesSectionRef = useRef();
    const delay = 5;

    const getAnimation = (ref) =>
        gsap.from(ref.current, {
            scaleX: 0,
            transformOrigin: '0px 0px',
            ease: 'none',
            duration: delay,
            paused: true,
        });

    useEffect(() => {
        setFirstProgress(getAnimation(firstRef));
    }, [firstRef]);
    useEffect(() => {
        setSecondProgress(getAnimation(secondRef));
    }, [secondRef]);
    useEffect(() => {
        setThirdProgress(getAnimation(thirdRef));
    }, [thirdRef]);
    useEffect(() => {}, []);

    const startAnimation = () => {
        if (timer.current) {
            clearInterval(timer.current);
        }
        timer.current = loopInterval('workflow');
    };

    let screenToProgress = {
        workflow: firstProgress,
        verification_link: secondProgress,
        verification_result: thirdProgress,
    };
    let screenList = ['workflow', 'verification_link', 'verification_result'];

    useIsomorphicLayoutEffect(() => {
        let scrollTrigger;
        if (
            IdentitiesSectionRef.current &&
            firstProgress &&
            secondProgress &&
            thirdProgress &&
            !timer.current
        ) {
            gsap.registerPlugin(ScrollTrigger);
            scrollTrigger = ScrollTrigger.create({
                trigger: '#' + IdentitiesSectionRef.current.id,
                pin: false, // pin the trigger element while active
                start: 'top bottom-=150px', // when the top of the trigger hits the top of the viewport
                end: '+=600', // end after scrolling 500px beyond the start
                onEnter: () => startAnimation(),
                //   onLeave: () => timer.current ? clearInterval(timer.current) : null,
                onEnterBack: () => startAnimation(),
                //   onLeaveBack: ()=> timer.current ? clearInterval(timer.current) : null
            });
        }

        return function cleanup() {
            if (scrollTrigger?.kill) scrollTrigger.kill();
            if (timer.current) {
                clearInterval(timer.current);
            }
        };
    }, [IdentitiesSectionRef, firstProgress, secondProgress, thirdProgress]);

    const resetAllProgressBars = () => {
        resetProgress(firstProgress);
        resetProgress(secondProgress);
        resetProgress(thirdProgress);
    };

    const loop = () => {
        resetAllProgressBars();

        let currentScreen;
        setScreenImg((screen) => {
            currentScreen = screen;
            return screen;
        });
        let nextIndex = (screenList.indexOf(currentScreen) + 1) % 3;
        setScreenImg(screenList[nextIndex]);
        startProgress(screenToProgress[screenList[nextIndex]]);

        cardLoop(nextIndex);
    };

    // Memoized because it has been added to the useMemo function
    const loopInterval = (startScreen) => {
        resetAllProgressBars();
        startProgress(screenToProgress[startScreen]);
        setScreenImg(startScreen);

        let intervalId = setInterval(function () {
            loop();
        }, delay * 1000);
        return intervalId;
    };

    const onCardClick = (screenName) => () => {
        if (timer.current) {
            clearInterval(timer.current);
        }
        timer.current = loopInterval(screenName);
    };

    const scrollCardRef = useRef(null);
    const firstCardRef = useRef(null);
    const secondCardRef = useRef(null);
    const thirdCardRef = useRef(null);

    let cardToSlide = {
        workflow: firstCardRef.current,
        verification_link: secondCardRef.current,
        verification_result: thirdCardRef.current,
    };

    const cardLoop = (nextIndex) => {
        gsap.to(scrollCardRef.current, {
            //screenList array contain all cards name
            scrollTo: {
                x: cardToSlide[screenList[nextIndex]],
                offsetX: 20,
            },
        });
    };

    useIsomorphicLayoutEffect(() => {
        gsap.registerPlugin(ScrollToPlugin);

        if (isMobile()) {
            return cardLoop();
        }
    }, []);

    return (
        <Container ref={IdentitiesSectionRef} id="Identities">
            <IdentitiesContainer>
                <Heading>Analyze Identities in seconds</Heading>
                <CardContainer>
                    <LeftSection ref={scrollCardRef}>
                        <CardWrapper>
                            <MobileImg
                                src={Workflow}
                                alt="Design your workflow"
                                width="auto"
                                height="auto"
                            />
                            <InfoCards onClick={onCardClick('workflow')} ref={firstCardRef}>
                                <CardHeader>
                                    <CardHeading>Design your Workflow </CardHeading>
                                    <GradientTag small={true} text="Design/Configure" />
                                </CardHeader>
                                <CardDesc>
                                    Users can verify themselves using knowledge quizzes,
                                    government-issued photo identification or their bank account.
                                    Additionally, you can choose authoritative database lookups and
                                    more.
                                </CardDesc>
                                <ProgressBar ref={firstRef}></ProgressBar>
                            </InfoCards>
                        </CardWrapper>
                        <CardWrapper>
                            <MobileImg
                                src={VerificationLink}
                                alt="Create a verification link"
                                width="auto"
                                height="auto"
                            />
                            <InfoCards
                                onClick={onCardClick('verification_link')}
                                ref={secondCardRef}
                            >
                                <CardHeader>
                                    <CardHeading>Create a Verification Link</CardHeading>
                                    <GradientTag small={true} text="Launch" />
                                </CardHeader>
                                <CardDesc>
                                    Use the no-code portal to send a unique verification link or
                                    integrate just “two” APIs to embed the flow in your app.
                                </CardDesc>
                                <ProgressBar ref={secondRef}></ProgressBar>
                            </InfoCards>
                        </CardWrapper>
                        <CardWrapper>
                            <MobileImg
                                src={VerificationResult}
                                alt="Get Verification Results"
                                width="auto"
                                height="auto"
                            />
                            <InfoCards
                                onClick={onCardClick('verification_result')}
                                ref={thirdCardRef}
                            >
                                <CardHeader>
                                    <CardHeading>Get Verification Results</CardHeading>
                                    <GradientTag small={true} text="Investigate/Analyze" />
                                </CardHeader>
                                <CardDesc>
                                    Analyze the KYC results after the user completes the process.
                                    Leverage the results to run deeper/more thorough background
                                    checks.
                                </CardDesc>
                                <ProgressBar ref={thirdRef}></ProgressBar>
                            </InfoCards>
                        </CardWrapper>
                    </LeftSection>
                    <RightSection>
                        <Screens
                            visible={'workflow' === screenImg}
                            src={Workflow}
                            alt="Design your workflow"
                            width="auto"
                            height="auto"
                            loading="lazy"
                        />
                        <Screens
                            visible={'verification_link' === screenImg}
                            src={VerificationLink}
                            alt="Create a verification link"
                            width="auto"
                            height="auto"
                            loading="lazy"
                        />
                        <Screens
                            visible={'verification_result' === screenImg}
                            src={VerificationResult}
                            alt="Get Verification Results"
                            width="auto"
                            height="auto"
                            loading="lazy"
                        />
                    </RightSection>
                </CardContainer>
            </IdentitiesContainer>
            <EllipseOne src={Ellipse1} alt="Ellipse 1" />
            <EllipseTwo src={Ellipse2} alt="Ellipse 2" />
        </Container>
    );
}

export default Identities;
